.notice-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #ffe680;
  color: #333;
  padding: 15px 20px;
  text-align: center;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    margin: 0 auto;
    font-size: 16px;
    font-weight: 500;
  }

  .close-btn {
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    color: #333;
    padding: 5px;

    &:hover {
      color: #000;
    }
  }
}
