.container-admin {
  width: 95%;
  margin: auto;
  max-width: 1300px;
  h3 {
    font-size: 33px;
    font-weight: 200;
    margin-bottom: 15px;
  }
  h4 {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 15px;
  }
}

.header-title {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}

.logout-button-container {
  text-align: right;
  margin-bottom: 20px;
}

.button-standard {
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  font-size: 16px;
  transition: background-color 0.3s;
  border-radius: 4px;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
  }
}

// Variantes de botones
.button-danger {
  background-color: #dc3545;

  &:hover {
    background-color: #c82333;
  }
}

.button-success {
  background-color: #28a745;

  &:hover {
    background-color: #218838;
  }
}

.button-logout {
  @extend .button-danger;
}

.form-container {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto 20px auto;
  box-sizing: border-box;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
}

.form-fields {
  display: flex;
  flex-direction: column;
  width: 45%;

  @media (max-width: 768px) {
    width: 100%;
    align-items: center;
  }

  input,
  select,
  button {
    margin-bottom: 10px;
    padding: 10px;
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
  }
  textarea {
    width: 100%;
    min-height: 100px;
    font-size: 16px;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  .button-group {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
    button {
      width: 48%;
      border-radius: 4px;
    }

    .button-upload {
      @extend .button-success;
    }

    .button-clear {
      @extend .button-danger;
    }
  }
}

.image-upload-container {
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 0 5%;

  @media (max-width: 768px) {
    width: 100%;
    align-items: center;
  }

  .custom-file-input {
    display: none;
  }

  .button-choose-files {
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    padding: 10px;
    font-size: 16px;
    margin-bottom: 10px;
    transition: background-color 0.3s;
    border-radius: 4px;
    &:hover {
      background-color: #0056b3;
    }
  }

  input[type="file"] {
    margin-bottom: 10px;
  }
}

.image-previews {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .image-preview {
    position: relative;

    .preview-image {
      display: block;
      width: 100px;
      height: auto;
      border: 2px solid #ddd;
      border-radius: 4px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .remove-image-button {
      position: absolute;
      top: 5px;
      right: 5px;
      background: rgba(255, 0, 0, 0.7);
      color: white;
      border: none;
      border-radius: 50%;
      cursor: pointer;
      padding: 5px 10px;
      font-size: 12px;
      transition: background-color 0.3s;

      &:hover {
        background: rgba(255, 0, 0, 0.9);
      }
    }
  }
}

.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-modal-content {
  position: fixed;
  width: 95%;
  height: 98vh;
  overflow-y: auto;
  margin: auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background: white;

  .modal-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    button {
      padding: 10px;
      font-size: 16px;
      cursor: pointer;
      width: 48%;
      border: none;
      transition: background-color 0.3s;

      &:hover {
        opacity: 0.8;
      }
    }

    .button-return {
      background-color: #6c757d;
      color: white;

      &:hover {
        background-color: #5a6268;
      }
    }

    .button-upload {
      @extend .button-success;
    }

    .button-clear {
      @extend .button-danger;
    }

    .button-delete {
      @extend .button-danger;
    }
  }

  .form-fields {
    display: flex;
    flex-direction: column;
    width: 100%;

    input,
    select,
    button {
      margin-bottom: 10px;
      padding: 10px;
      font-size: 16px;
      width: 100%;
      box-sizing: border-box;
    }
  }

  .image-upload-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .image-previews {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;

      .image-preview {
        .preview-image {
          width: 100px;
        }
      }
    }
  }
}

.small-modal {
  width: auto;
  height: auto;
}

.job-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  .job-details {
    display: flex;
    flex-direction: row;
    flex: 1;
    gap: 10px;

    .job-info {
      flex: 1;
      max-width: 70%;
      p {
        margin: 0 0 10px 0;
      }
      span {
        font-weight: bold;
      }
    }

    .job-image {
      width: 49.9%;
      height: auto;
      max-height: 60vh;
      object-fit: cover;
      border-radius: 4px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
  }
}
.job-actions {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;
  .button-edit,
  .button-delete {
    @extend .button-standard;
    width: 25%;
  }

  .button-delete {
    @extend .button-danger;
    width: 15%;
  }
}
.accordion {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.accordion-button .collapsed {
  border: 1px solid red;
}
.search-bar {
  margin-bottom: 20px;
  width: 100%;
  max-width: 400px;
  margin: 20px;
}
