.card-def {
  padding: 20px;
  text-align: center;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.29);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.06);
  h3 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    margin-bottom: 10px;
    color: #333;
  }

  p {
    font-size: 16px;
    color: #777;
    line-height: 1.4;
  }
  a {
    display: inline-block;
    background: linear-gradient(45deg, #e69500, #cc7a00, #a65c00);
    min-width: 84%;
    color: white;
    margin: 15px 0;
    padding: 6px;
    text-decoration: none;
    border-radius: 25px;
    text-align: center;
    animation: gradientTransition 5s ease infinite;
    transform: scale(1);
    transition: transform 0.2s ease, background 0.3s ease;
  }

  a:hover {
    background: linear-gradient(45deg, #cc7a00, #a65c00, #8b4a00);
    transform: scale(1.02);
  }

  a:active {
    transform: scale(0.95);
  }
}
@keyframes gradientTransition {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 50%;
  }
}
