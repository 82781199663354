.nav-bar-main {
  background: #333333;
  background: linear-gradient(to top, #333333 0%, #1f1f1f 100%);
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("/../public/img/imgFixed/portada.jpg");
  background-size: cover;
  background-position: top;
  color: #ffffff;
  padding: 4vh;
  align-items: flex-start !important;
  .navbar-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  a {
    text-decoration: none;
    text-decoration-skip-ink: none;
  }
  .brand-name {
    display: flex;
    align-items: center;
    font-size: 24px;
    color: #fff;
    width: 100%;
    img {
      width: 50px;
      height: 50px;
      margin-right: 10px;
      @media (max-width: 576px) {
        width: 8vw;
        height: 8vw;
      }
    }
    h2 {
      margin: auto 0;
      color: white;
      white-space: normal;
      &:hover {
        color: white;
      }
      @media (max-width: 768px) {
        font-size: 34px;
        text-align: center;
      }
      @media (max-width: 576px) {
        font-size: 8vw;
      }
    }
  }

  .menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .nav-link {
      color: #fff;
      margin-left: 20px;
      font-size: 18px;
      position: relative;

      &:hover {
        color: #71b100;
        text-decoration: none;
        text-shadow: 2px 2px 5px rgba(43, 42, 42, 0.7);
      }

      &.active {
        color: #71b100;
        font-weight: bold;
        text-shadow: 2px 2px 5px rgba(43, 42, 42, 0.7);
      }

      &::before {
        content: "";
        position: absolute;
        bottom: 2px;
        left: 50%;
        width: 0;
        height: 2px;
        background-color: #71b100;
        transition: width 0.3s ease, left 0.3s ease;
        transform: translateX(-50%);
      }

      &:hover::before {
        width: 65%;
        left: 50%;
      }
    }
  }
  .navbar-toggler {
    outline: none;
    border: white;
    box-shadow: none;
  }
  .navbar-toggler:focus {
    outline: none;
    box-shadow: none;
  }
  .navbar-toggler-icon {
    background-image: none !important;
    background-color: white;
    height: 3px;
    width: 25px;
    margin: 10px 0;
    position: relative;
    transition: all 0.35s ease-out;
    transform-origin: center;
  }

  .navbar-toggler-icon::before {
    display: block;
    background-color: white;
    height: 3px;
    content: "";
    position: relative;
    top: -7px;
    transition: all 0.15s ease-out;
    transform-origin: center;
  }

  .navbar-toggler-icon::after {
    display: block;
    background-color: white;
    height: 3px;
    content: "";
    position: relative;
    top: 4px;
    transition: all 0.35s ease-out;
    transform-origin: center;
  }

  .navbar-dark .navbar-toggler-icon,
  .navbar-dark .navbar-toggler-icon::before,
  .navbar-dark .navbar-toggler-icon::after {
    background-color: var(--bs-gray-100);
  }

  .navbar-toggler:not(.collapsed) .navbar-toggler-icon {
    transform: rotate(45deg);
  }

  .navbar-toggler:not(.collapsed) .navbar-toggler-icon::before {
    opacity: 0;
  }

  .navbar-toggler:not(.collapsed) .navbar-toggler-icon::after {
    transform: rotate(-90deg) translateX(7px);
  }

  .collapsed {
    border-color: rgba(255, 255, 255, 0.1);
  }
}

.nav-bar-home {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("/../public/img/imgFixed/portada.jpg");
  background-size: cover;
  background-position: top center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  height: 100vh;
  margin-bottom: 5vh;
  h1 {
    max-width: 12em;
    position: absolute;
    bottom: 5%;
    left: 32%;
    transform: translate(-50%, -50%);
    z-index: 1;
    white-space: normal;
  }
}

#navbar-nav {
  flex-grow: 0;
}

@media (max-width: 991px) {
  .nav-bar-main {
    .navbar-content {
      .menu {
        .nav-link {
          align-self: flex-end;
          margin-left: 0;
        }
      }
    }
  }
}
@media (max-width: 800px) {
  .nav-bar-home {
    background-size: auto 100vh;
    h1 {
      bottom: 5%;
      left: 40%;
    }
  }
}
@media (max-width: 500px) {
  .nav-bar-main {
    .navbar-content {
      justify-content: center !important;
      .menu {
        .nav-link {
          align-self: center;
        }
      }
    }
  }
}
